.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  margin: 0;
  font-family: Arial, sans-serif;
  /* background-color: #f4f4f9; Light background for aesthetics */
  text-align: center;
}

.home-container h1 {
  font-size: 3rem;

  margin: 0;
}

.home-container p {
  font-size: 1.5rem;
  color: #555; /* Lighter gray text for subheading */
  margin: 0.5rem 0 0;
}
