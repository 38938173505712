* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  height: 100%;
  font-family: Arial, sans-serif;
  background-color: #1e1e1e;
  color: #d4d4d4;
}

#navbar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 25px; /* Adjusted height */
  background-color: #252c31;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  color: #d4d4d4;
}

/* File Explorer (Left Panel) */
#files {
  position: absolute;
  top: 25px; /* Below the adjusted top menu */
  left: 0;
  width: 200px;
  height: calc(
    100% - 275px
  ); /* Account for the top menu and new terminal height */
  background-color: #2d353b;
  overflow-y: auto;
  padding: 10px;
  color: #d4d4d4;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

#files ul {
  list-style: none;
  padding-left: 15px;
  margin: 5px 0;
}

#files li {
  padding: 5px 10px;
  cursor: pointer;
}

#files li:hover {
  background-color: #444444;
}

#files .category {
  font-weight: bold;
  color: #ffffff;
  text-transform: capitalize;
  padding: 8px 10px;
  background-color: #3a3f44;
  border-radius: 4px;
}

#files .file {
  padding-left: 20px; /* Indent files */
}

#files .file:hover {
  background-color: #555555;
  color: #ffffff;
}

#files .file.active {
  background-color: #a7c080; /* Highlight active file */
  color: #232a2e;
  font-weight: bold;
  border-radius: 4px;
}

#editor {
  position: absolute;
  top: 25px; /* Below the adjusted top menu */
  left: 200px; /* Next to the file explorer */
  width: calc(
    100% - 550px
  ); /* Leave space for the file explorer and right panel */
  height: calc(
    100% - 275px
  ); /* Account for the top menu and new terminal height */
  padding: 20px;
  font-family: monospace;
  line-height: 1.5;
  overflow-y: auto; /* Enable scrolling for overflowing content */
  overflow-x: auto; /* Enable horizontal scrolling if necessary */
  background-color: #2f383e;
  color: #d4d4d4;
}

/* Add hover styles for the entire line */
.ace-resume-custom .ace_line:hover {
  background-color: #363846 !important;
  transition: background-color 0.2s ease;
}

/* Make sure selection doesn't conflict with hover */
.ace-resume-custom .ace_selection {
  background-color: #44475a !important;
}

/* Active line highlight should be slightly different */
.ace-resume-custom .ace_active-line {
  background-color: #313340 !important;
}

.ace-resume-custom .ace_special_words {
  cursor: pointer;
}

#editor-right {
  border-left: 2px solid black;
  position: absolute;
  top: 25px; /* Below the adjusted top menu */
  right: 0;
  width: 350px; /* Fixed width for the right panel */
  height: calc(
    100% - 275px
  ); /* Account for the top menu and new terminal height */
  overflow-y: auto; /* Enable scrolling for overflowing content */
  padding: 10px;
  background-color: #2f383e;
  color: #d4d4d4;
  font-family: monospace;
  box-sizing: border-box; /* Include padding in width/height calculations */
}

.tooltip {
  position: absolute;
  display: none;
  background: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  z-index: 1000;
}

#terminal {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 250px; /* Adjusted height */
  background-color: #272e33;
  font-family: monospace;
  overflow-y: auto;
  padding: 10px;
  color: white;
  font-size: 16px;
  font-family: monospace;
}

#host {
  color: #83c092; /* Custom color for host */
}

#dir {
  color: #7fbbb3; /* Custom color for directory */
}

.prompt {
  display: flex; /* Ensure prompt and input stay inline */
  align-items: center;
  margin-bottom: 5px; /* Space between prompts */
}

#cli {
  background-color: transparent; /* Transparent background for input */
  color: white; /* Input text color to match terminal */
  border: none; /* Remove border for input */
  outline: none; /* Remove focus outline */
  font-family: monospace; /* Match terminal font */
  flex: 1; /* Ensure input takes remaining space */
  animation: blink 1s step-end infinite; /* Blinking caret animation */
}

#cli::placeholder {
  color: #555555; /* Placeholder text color */
}

.output {
  margin-left: 2px; /* Slight indent for command output */
  white-space: pre; /* Preserve line breaks and spacing in output */
  color: #d3d3d3; /* Dimmer color for output to differentiate from prompt */
}

@keyframes blink {
  0% {
    border-right: 1px solid white;
  }
  50% {
    border-right: 1px solid transparent;
  }
  100% {
    border-right: 1px solid white;
  }
}

.readme-content {
  border-left: 2px solid black;
  position: absolute;
  top: 25px; /* Below the adjusted top menu */
  right: 0;
  width: 350px; /* Fixed width for the right panel */
  height: calc(
    100% - 275px
  ); /* Account for the top menu and new terminal height */
  overflow-y: auto; /* Enable scrolling for overflowing content */
  padding: 20px;
  background-color: #2f383e;
  color: #d4d4d4;
  font-family: monospace;
  box-sizing: border-box; /* Include padding in width/height calculations */
}

/* Header styles */
.readme-content h1 {
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 10px;
}

.readme-content h2 {
  font-size: 16px;
  color: #83c092; /* Light green for subheadings */
  margin-bottom: 8px;
}

/* Paragraph styles */
.readme-content p {
  font-size: 14px;
  line-height: 1.5;
  color: #d4d4d4;
  margin-bottom: 10px;
}

/* GIF container styles */
.readme-gif {
  margin-bottom: 20px;
  text-align: center;
}

.readme-gif img {
  max-width: 100%;
  height: auto;
  border: 2px solid #444444;
  border-radius: 4px;
}
