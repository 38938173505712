ace-resume-custom {
  background-color: #2f383e;
  color: #f8f8f2;
}
.ace-resume-custom .ace_gutter {
  background: #2f383e;
  color: #6272a4;
}

.ace-resume-custom .ace_special_words {
  color: #ff5555; /* Bright red for special words */
  font-weight: bold;
}
.ace-resume-custom .ace_keyword {
  color: #ff79c6;
}
.ace-resume-custom .ace_keyword.ace_control {
  color: #ff79c6;
}
.ace-resume-custom .ace_support.ace_function {
  color: #50fa7b;
}
.ace-resume-custom .ace_constant.ace_language {
  color: #bd93f9;
}
.ace-resume-custom .ace_variable.ace_language {
  color: #f1fa8c;
}
.ace-resume-custom .ace_constant.ace_numeric {
  color: #bd93f9;
}
.ace-resume-custom .ace_string {
  color: #83c092;
}
.ace-resume-custom .ace_comment {
  color: #6272a4;
}
.ace-resume-custom .ace_keyword.ace_operator {
  color: #ff79c6;
}
.ace-resume-custom .ace_namespace.ace_qualifier {
  color: #8be9fd; /* Cyan for qualifiers */
  font-style: italic;
}
.ace-resume-custom .ace_namespace.ace_member {
  color: #ffb86c;
  font-weight: bold;
}

.ace-resume-custom .ace_namespace.ace_lang {
  color: #e67e80;
  font-weight: bold;
}

/* CSS for documentation comments */
.ace_comment.ace_block.ace_documentation {
  color: #6a9955; /* Light green for documentation comments */
  font-style: italic; /* Italic style for emphasis */
  background-color: rgba(106, 153, 85, 0.1); /* Light green background */
  padding-left: 4px; /* Optional padding for indentation */
  border-left: 3px solid #6a9955; /* Optional left border for visual separation */
}
/* CSS for general block comments */
.ace_comment.ace_block {
  color: #888888; /* Gray for general block comments */
  font-style: normal; /* Regular font style */
  background-color: rgba(136, 136, 136, 0.1); /* Light gray background */
  padding-left: 2px; /* Optional padding */
  border-left: 2px solid #888888; /* Optional left border */
}
