.photo-view-container {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;
  position: relative;
  background-color: #000;
}

.photo-view-inner {
  min-height: 100%;
  position: relative;
}

.photo-container {
  height: 100vh;
  width: 100%;
  scroll-snap-align: center;
  scroll-snap-stop: always;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.photo {
  max-height: 90vh;
  max-width: 90%;
  object-fit: contain;
  border-radius: 4px;
}

.photo-info {
  position: absolute;
  bottom: 20px;
  left: 20px;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 8px 16px;
  border-radius: 20px;
  color: white;
  font-size: 14px;
}

.photo-id {
  margin-right: 12px;
  font-weight: bold;
}

.photo-category {
  text-transform: capitalize;
  color: #ccc;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.photo-view-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.photo-view-container {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
