/* Category styles */
.photo-grid-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 16px;
  max-width: 70%; /* Match the photo-grid max-width */
  margin: 0 auto;
}

.category-scroll-container {
  position: sticky;
  top: 0;
  /* background-color: #fff; */
  padding: 8px 0;
  z-index: 10;
  border-bottom: 1px solid #eee;
}

.category-list {
  display: flex;
  gap: 16px;
  overflow-x: auto;
  padding: 0 16px;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  white-space: nowrap;
}

.category-list::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.category-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  padding: 8px;
  border: none;
  background: none;
  cursor: pointer;
  transition: all 0.2s ease;
  min-width: 72px;
  color: white;
}

.category-item:hover {
  opacity: 0.8;
}

.category-item.selected {
  color: green;
}

.category-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  /* background-color: #f5f5f5; */
  transition: all 0.2s ease;
}

.category-item.selected .category-icon {
  background-color: #e6f0ff;
}

.category-name {
  font-size: 14px;
  text-transform: capitalize;
}

/* Original photo grid styles */
.photo-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Always 3 columns */
  gap: 10px;
  padding: 10px;
  justify-content: center;
  align-content: center;
  max-width: 70%; /* Desktop and larger screens */
  width: 100%; /* Mobile screens */
  margin: 0 auto; /* Center the grid */
  box-sizing: border-box;
}

.photo-item {
  position: relative;
  overflow: hidden;
  width: 100%; /* Flexible to maintain grid layout */
  aspect-ratio: 1 / 1; /* Ensures the photo-item is a perfect square */
  border: 2px solid #ddd;
  border-radius: 5px;
}

.photo-item img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the square without distortion */
  transform: translate(-50%, -50%);
}

@media (max-width: 768px) {
  .photo-grid-container,
  .photo-grid {
    max-width: 100%; /* Full width on mobile */
  }

  .category-scroll-container {
    margin: 0 -16px; /* Extend to full width on mobile */
  }
}
